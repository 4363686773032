<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height style="background-color: #C52C34">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4 xl3>
            <v-card rounded class="pa-4 elevation-1">
              <v-layout align-center justify-center class="pt-4">
                <p class="title">{{ $t('codeLogin.titulo') }}</p>
              </v-layout>
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit="signIn">
                  <v-text-field
                    data-cy="email"
                    :label="$t('codeLogin.email')"
                    name="login"
                    prepend-icon="email"
                    type="email"
                    v-model="email"
                    :rules='emailRules'
                    required
                  ></v-text-field>

                  <v-text-field
                    data-cy="code"
                    :label="$t('codeLogin.code')"
                    name="code"
                    prepend-icon="insert_link"
                    v-model="code"
                    :rules='fieldRules'
                    required
                  ></v-text-field>

                  <v-spacer></v-spacer>
                  <v-layout justify-end>
                    <v-btn block data-cy="signin" class="mt-4 mb-2" type="submit" color="primary" :disabled="!valid" @click="signIn">{{ $t('codeLogin.botao') }}</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <life-phase-dialog id="lifePhaseDialog" />
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import SnackbarService from '@/components/snackbar/SnackbarService'
import { mapActions } from 'vuex'
import LifePhaseDialog from '@/components/dialogs/lifephase/LifePhaseDialog'
import LifePhaseDialogService from '@/components/dialogs/lifephase/LifePhaseDialogService'

export default {
  name: 'CodeLogin',
  components: {
    LifePhaseDialog
  },
  data: () => {
    return {
      valid: false,
      email: null,
      code: null,
      fieldRules: [],
      emailRules: []
    }
  },
  mounted () {
    this.fieldRules = [v => !!v || this.$t('validacao.campoObrigatorio')]
    this.emailRules = [
      v => !!v || this.$t('validacao.campoObrigatorio'),
      v => /.+@.+/.test(v) || this.$t('validacao.emailValido')
    ]
  },
  methods: {
    ...mapActions(['fetchUser']),
    signIn (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        firebase.auth().signInAnonymously()
          .then(() => {
            let user = firebase.auth().currentUser
            user.code = this.code

            firebase.database().ref().child('codes')
              .orderByChild('id')
              .equalTo(user.code)
              .once('value')
              .then(snap => {
                if (snap.exists()) {
                  this.fetchUser(user)
                  LifePhaseDialogService.on('lifePhaseDialog')
                } else {
                  SnackbarService.on('Snackbar', this.$t('codeLogin.codigoInvalido'))
                }
              })
          })
          .catch(error => {
            console.debug(error)
            SnackbarService.on('Snackbar', this.$t('erroInesperado'))
          })
      }
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="isOn"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card class="fill-height">
      <v-toolbar card dark color="primary">
        <v-btn icon dark @click="isOn = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-layout justify-center align-center fill-height>
        <v-layout column align-center justify-center>
          <v-card-title
            class="headline"
            primary-title
          >
            <p class="heading">Escolha o questionário</p>
          </v-card-title>
          <v-btn style="width: 150px" data-cy="adult" outline large @click="openTerms(adult)">{{ $t('tab.questionario.adulto') }}</v-btn>
          <!-- <v-btn style="width: 150px" data-cy="adolescent" outline large @click="openTerms(adolescent)" color="purple">{{ $t('tab.questionario.adolescente') }}</v-btn> -->
        </v-layout>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import LifePhaseDialogService from './LifePhaseDialogService'
import { QuestionnaireType } from '@/helpers/QuestionnaireType.js'
import util from '@/router/util'

export default {
  name: 'LifePhaseDialog',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOn: false,
      title: String,
      message: String
    }
  },
  created () {
    LifePhaseDialogService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
    })
  },
  destroyed () {
    LifePhaseDialogService.unsubscribe(this.id)
  },
  computed: {
    adult () {
      return QuestionnaireType.ADULT
    },
    adolescent () {
      return QuestionnaireType.ADOLESCENT
    }
  },
  methods: {
    openTerms (questionnaireType) {
      this.$router.push(util.path.research.TERMS_AND_CONDITION)
    }
  }
}
</script>

import Vue from 'vue'

export default class LifePhaseDialogService {
  static _eventBus = new Vue()

  static subscribe (id, callBack) {
    return LifePhaseDialogService._eventBus.$on(id, callBack)
  }

  static unsubscribe (id) {
    return LifePhaseDialogService._eventBus.$off(id)
  }

  static on (id, title) {
    LifePhaseDialogService._eventBus.$emit(id, { isOn: true })
  }

  static off (id) {
    LifePhaseDialogService._eventBus.$emit(id, { isOn: false })
  }
}
